import {
  AllInclusive as AllInclusiveIcon,
  AssignmentLate as AssignmentLateIcon,
  Attribution as AttributionIcon,
  Block as BlockIcon,
  DateRange as DateRangeIcon,
  EventBusy as EventBusyIcon,
  PersonAdd as PersonAddIcon,
  RemoveCircle as RemoveCircleIcon,
  Today as TodayIcon,
} from "@mui/icons-material";
import {
  Chip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { PatientsContext } from "components/PatientsProvider";
import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";
import MaxSizeLoading from "../../../MaxSizeLoading";

function PatientsDrawerItems({ useDense }) {
  const { setCategoryKey, categoryKey, patientCategoriesMap } =
    React.useContext(PatientsContext);

  if (!setCategoryKey || !categoryKey || !patientCategoriesMap) {
    return <MaxSizeLoading />;
  }
  return (
    <React.Fragment>
      <List dense={useDense}>
        <ListSubheader>Actions</ListSubheader>
        <Link href="/patients/add-new-patient" passHref legacyBehavior>
          <ListItemButton>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Patient" />
          </ListItemButton>
        </Link>
      </List>
      <List dense={useDense}>
        <ListSubheader>Filters</ListSubheader>
        <ListItemButton
          selected={categoryKey === "active"}
          onClick={() => {
            setCategoryKey("active");
          }}
        >
          <ListItemIcon>
            <AttributionIcon />
          </ListItemIcon>
          <ListItemText primary="Active Patients" />
          <Chip label={patientCategoriesMap.active.length} />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "pastDue"}
          onClick={() => {
            setCategoryKey("pastDue");
          }}
        >
          <ListItemIcon>
            <AssignmentLateIcon />
          </ListItemIcon>
          <ListItemText primary="Past Due" />
          <Chip label={patientCategoriesMap.pastDue.length} color="error" />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "dueWithinOneWeek"}
          onClick={() => {
            setCategoryKey("dueWithinOneWeek");
          }}
        >
          <ListItemIcon>
            <DateRangeIcon />
          </ListItemIcon>
          <ListItemText primary="Due Within One Week" />
          <Chip label={patientCategoriesMap.dueWithinOneWeek.length} />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "noActivePrescriptions"}
          onClick={() => {
            setCategoryKey("noActivePrescriptions");
          }}
        >
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText primary="No Active Prescriptions" />
          <Chip label={patientCategoriesMap.noActivePrescriptions.length} />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "appointmentToday"}
          onClick={() => {
            setCategoryKey("appointmentToday");
          }}
        >
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary="Today's Appointments" />
          <Chip label={patientCategoriesMap.appointmentToday.length} />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "needsAppointment"}
          onClick={() => {
            setCategoryKey("needsAppointment");
          }}
        >
          <ListItemIcon>
            <EventBusyIcon />
          </ListItemIcon>
          <ListItemText primary="Needs Appointment" />
          <Chip label={patientCategoriesMap.needsAppointment.length} />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "all"}
          onClick={() => {
            setCategoryKey("all");
          }}
        >
          <ListItemIcon>
            <AllInclusiveIcon />
          </ListItemIcon>
          <ListItemText primary="All Patients" />
          <Chip label={patientCategoriesMap.all.length} />
        </ListItemButton>
        <ListItemButton
          selected={categoryKey === "inactive"}
          onClick={() => {
            setCategoryKey("inactive");
          }}
        >
          <ListItemIcon>
            <RemoveCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Inactive Patients" />
          <Chip label={patientCategoriesMap.inactive.length} />
        </ListItemButton>
      </List>
    </React.Fragment>
  );
}

PatientsDrawerItems.propTypes = {
  useDense: PropTypes.bool,
};

PatientsDrawerItems.defaultProps = {
  useDense: false,
};
export default PatientsDrawerItems;
