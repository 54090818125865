import {
  AccountTree as AccountTreeIcon,
  Assessment as AssessmentIcon,
  Checklist as ChecklistIcon,
  Contacts as ContactsIcon,
  Dashboard as DashboardIcon,
  EventRepeat as EventRepeatIcon,
  ExpandLess,
  ExpandMore,
  Factory as FactoryIcon,
  Medication as MedicationIcon,
  People as PeopleIcon,
  ReceiptLong as ReceiptLongIcon,
  Send as SendIcon,
  SyncAlt as SyncAltIcon,
  Tag as TagIcon,
} from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { signIn, useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import medicationSvg from "public/icons/medication.svg";
import prescriberSvg from "public/icons/prescriber.svg";
import { Fragment, useState } from "react";
import CustomSvgIcon from "../../../CustomSvgIcon";
import AdministratorsDrawerItems from "./AdministratorsDrawerItems";
import MedicationsDrawerItems from "./MedicationsDrawerItems";
import PatientsDrawerItems from "./PatientsDrawerItems";
import PrescribersDrawerItems from "./PrescribersDrawerItems";
import TagsDrawerItems from "./TagsDrawerItems";

function DrawerItems() {
  const theme = useTheme();
  const useDense = useMediaQuery(theme.breakpoints.up("md"));

  const { status, data: session } = useSession({
    required: true,
    onUnauthenticated() {
      signIn("google");
    },
  });

  const { pathname } = useRouter();

  const [workflowListOpen, setWorkflowListOpen] = useState(
    pathname === "/production-workflow"
  );

  let conditionalDrawerItems = null;

  switch (pathname) {
    case "/patients":
      conditionalDrawerItems = <PatientsDrawerItems useDense={useDense} />;
      break;
    case "/prescribers":
      conditionalDrawerItems = <PrescribersDrawerItems useDense={useDense} />;
      break;
    case "/medications":
      conditionalDrawerItems = <MedicationsDrawerItems useDense={useDense} />;
      break;
    case "/administrators":
      conditionalDrawerItems = (
        <AdministratorsDrawerItems useDense={useDense} />
      );
      break;
    case "/tags":
      conditionalDrawerItems = <TagsDrawerItems useDense={useDense} />;
      break;
    default:
      break;
  }

  const isParagonPartnersWorkflowVisible =
    session?.user?.email?.endsWith("@rxparagon.com") ||
    session?.user?.email === "colby@rexrx.com";

  return (
    <Fragment>
      {conditionalDrawerItems}
      <List dense={useDense}>
        <ListSubheader>Navigation</ListSubheader>
        <Link href="/prescriptions" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/prescriptions"}>
            <ListItemIcon>
              <MedicationIcon />
            </ListItemIcon>
            <ListItemText primary="Prescriptions" />
          </ListItemButton>
        </Link>
        <ListItemButton
          onClick={() => {
            setWorkflowListOpen(!workflowListOpen);
          }}
        >
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Workflow" />
          {workflowListOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={workflowListOpen} timeout="auto" unmountOnExit>
          <List disablePadding dense={useDense}>
            <Link href="/production-workflow" passHref legacyBehavior>
              <ListItemButton
                selected={pathname === "/production-workflow"}
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <FactoryIcon />
                </ListItemIcon>
                <ListItemText primary="Production" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Link href="/administrations" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/administrations"}>
            <ListItemIcon>
              <ChecklistIcon />
            </ListItemIcon>
            <ListItemText primary="Administrations" />
          </ListItemButton>
        </Link>
        <Link href="/deliveries" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/deliveries"}>
            <ListItemIcon>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary="Deliveries" />
          </ListItemButton>
        </Link>
        <Link href="/notifications" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/notifications"}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </Link>
        <Link href="/owes" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/owes"}>
            <ListItemIcon>
              <SyncAltIcon />
            </ListItemIcon>
            <ListItemText primary="Owes" />
          </ListItemButton>
        </Link>
        <Link href="/patients" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/patients"}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Patients" />
          </ListItemButton>
        </Link>
        <Link href="/prescribers" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/prescribers"}>
            <ListItemIcon>
              <CustomSvgIcon svgFile={prescriberSvg} />
            </ListItemIcon>
            <ListItemText primary="Prescribers" />
          </ListItemButton>
        </Link>
        <Link href="/medications" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/medications"}>
            <ListItemIcon>
              <CustomSvgIcon svgFile={medicationSvg} />
            </ListItemIcon>
            <ListItemText primary="Medications" />
          </ListItemButton>
        </Link>
        <Link href="/tags" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/tags"}>
            <ListItemIcon>
              <TagIcon />
            </ListItemIcon>
            <ListItemText primary="Tags" />
          </ListItemButton>
        </Link>
        <Link href="/reports" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/reports"}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </Link>
        <Link href="/administrators" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/administrators"}>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary="Administrators" />
          </ListItemButton>
        </Link>
        <Link href="/" passHref legacyBehavior>
          <ListItemButton selected={pathname === "/"}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
        {status === "authenticated" && isParagonPartnersWorkflowVisible && (
          <Fragment>
            <ListSubheader>Paragon Partners Workflow</ListSubheader>
            <Link href="/cycles" passHref legacyBehavior>
              <ListItemButton selected={pathname === "/cycles"}>
                <ListItemIcon>
                  <EventRepeatIcon />
                </ListItemIcon>
                <ListItemText primary="Cycles" />
              </ListItemButton>
            </Link>
          </Fragment>
        )}
      </List>
    </Fragment>
  );
}

export default DrawerItems;
