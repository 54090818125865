import { addDays, isPast, isToday, isWithinInterval, set } from "date-fns";
import { useAllPatientsSubscription } from "models/Patient";
import { usePatientPrescriptionsMapSubscription } from "models/Prescription";
import PropTypes from "prop-types";
import React from "react";

// create a React context that will be used to pass patients to children components
export const PatientsContext = React.createContext();

function PatientsProvider({ children }) {
  // Real-time subscription to all patients.
  const [allPatients, setAllPatients] = React.useState();
  useAllPatientsSubscription(setAllPatients);
  // Real-time subscription to all prescriptions (for all patients), formatted as a map with the patientId as the key. This was the only way I could figure out to get real-time updates for patients AND prescriptions.
  const [prescriptionsMap, setPrescriptionsMap] = React.useState();
  usePatientPrescriptionsMapSubscription(setPrescriptionsMap);
  // Array of patients with their prescriptions added.
  const [patientsAndPrescriptions, setPatientsAndPrescriptions] =
    React.useState();

  // Effect that will run when the allPatients or prescriptionsMap is updated. It maps through all of the patients and adds their (real-time updated) prescriptions. This is ultimately the list of patients/prescriptions that is being filtered and sorted and passed to the components that subscribe to this context.
  React.useEffect(() => {
    if (!allPatients || !prescriptionsMap) return;
    setPatientsAndPrescriptions(
      allPatients.map((patient) => ({
        ...patient,
        prescriptions: prescriptionsMap[patient.id],
      }))
    );
  }, [allPatients, prescriptionsMap]);

  // We need to be able to track updates via subscription for the list that contains all patients (unsorted and unflitered). We also need to be able to track a filtered/sorted version of the list. Sorting and filtering options can be changed from within different components because we are passing the update function as part of the Context Provider value. If a new patient is added, we need to update ALL patients, and then subsequently update the filtered/sorted version so account for those changes.
  const [patientSublist, setPatientSublist] = React.useState();
  const [categoryKey, setCategoryKey] = React.useState("active");

  const patientCategoriesMap = React.useMemo(() => {
    if (patientsAndPrescriptions?.length) {
      return {
        active: patientsAndPrescriptions.filter(
          (patient) => patient.status !== "inactive"
        ),
        dueWithinOneWeek: patientsAndPrescriptions.filter((patient) => {
          return patient?.prescriptions?.some(
            (prescription) =>
              prescription.status === "active" &&
              isWithinInterval(prescription.nextInjectionDate, {
                start: set(new Date(), {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                }),
                end: addDays(
                  set(new Date(), {
                    hours: 11,
                    minutes: 59,
                    seconds: 59,
                    milliseconds: 999,
                  }),
                  7
                ),
              })
          );
        }),
        pastDue: patientsAndPrescriptions.filter((patient) => {
          return patient?.prescriptions?.some(
            (prescription) =>
              prescription.status === "active" &&
              isPast(prescription.nextInjectionDate)
          );
        }),
        noActivePrescriptions: patientsAndPrescriptions.filter((patient) => {
          return !patient?.prescriptions?.some(
            (prescription) => prescription.status === "active"
          );
        }),
        appointmentToday: patientsAndPrescriptions.filter((patient) => {
          return patient?.prescriptions?.some(
            (prescription) =>
              prescription.status === "active" &&
              prescription.appointmentDateTime &&
              isToday(prescription.appointmentDateTime.toDate())
          );
        }),
        needsAppointment: patientsAndPrescriptions.filter((patient) => {
          return patient?.prescriptions?.some(
            (prescription) =>
              prescription.status === "active" &&
              !prescription.appointmentDateTime
          );
        }),
        all: patientsAndPrescriptions,
        inactive: patientsAndPrescriptions.filter(
          (patient) => patient.status === "inactive"
        ),
      };
    }
  }, [patientsAndPrescriptions]);

  React.useEffect(() => {
    if (patientCategoriesMap) {
      setPatientSublist(patientCategoriesMap[categoryKey]);
    }
  }, [allPatients, categoryKey, patientCategoriesMap]);

  const value = React.useMemo(
    () => ({
      allPatients,
      patientsAndPrescriptions,
      patientCategoriesMap,
      categoryKey,
      setCategoryKey,
      patientSublist,
    }),
    [
      allPatients,
      patientsAndPrescriptions,
      patientCategoriesMap,
      categoryKey,
      patientSublist,
    ]
  );

  return (
    <PatientsContext.Provider value={value}>
      {children}
    </PatientsContext.Provider>
  );
}

PatientsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default PatientsProvider;
