import { auth, db } from "config/firebaseClient";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
  Timestamp,
} from "firebase/firestore";
import { useEffect } from "react";
import { updateGoogleCalendarEvent } from "./InjectionCalendarEvents";
import { ensureValueIsDate } from "lib/shared/ensureValueIsDate";
import format from "date-fns/format";
import { type ConfirmOptions } from "material-ui-confirm";

export async function addPatient({
  firstName,
  lastName,
  alias,
  birthdate,
  gender,
  homeAddress,
  phone,
  altPhone,
  email,
  status,
  allergies,
  defaultServiceLocation,
  defaultDispensingPharmacy,
  patientComments,
  locationComments,
}: {
  [x: string]: string;
}) {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error("No current user found");
  }

  const newPatientRef = doc(collection(db, "patients"));

  await setDoc(newPatientRef, {
    id: newPatientRef.id,
    firstName,
    lastName,
    alias,
    birthdate,
    gender,
    homeAddress,
    phone,
    altPhone,
    email,
    status,
    allergies,
    defaultServiceLocation,
    defaultDispensingPharmacy,
    patientComments,
    locationComments,
    createdAt: new Date(),
    createdBy: {
      uid: currentUser.uid,
      name: currentUser.displayName,
    },
  });

  return newPatientRef;
}

export async function updatePatient(
  updatedPatientData: Patient,
  confirm: (options?: ConfirmOptions) => Promise<void>
) {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error("No current user found");
  }

  const updatedBy = {
    uid: currentUser.uid,
    name: currentUser.displayName,
  };

  const batch = writeBatch(db);
  const patientRef = doc(db, "patients", updatedPatientData.id);
  const patientDocSnapshot = await getDoc(patientRef);

  let currentDefaultServiceLocation: {
    address: string;
    description: string;
  } | null = null;

  if (patientDocSnapshot.exists()) {
    const currentPatientData = patientDocSnapshot.data();
    currentDefaultServiceLocation = currentPatientData.defaultServiceLocation;
  }

  batch.update(patientRef, {
    ...updatedPatientData,
    updatedBy,
  });

  if (
    Boolean(updatedPatientData.firstName) ||
    Boolean(updatedPatientData.lastName) ||
    Boolean(updatedPatientData.birthdate)
  ) {
    const cycleQuery = query(
      collection(db, "cycles"),
      where("status", "==", "active"),
      where("patient.id", "==", updatedPatientData.id)
    );

    const cyclesSnapshot = await getDocs(cycleQuery);
    cyclesSnapshot.forEach((cycleDoc) => {
      const currentCycleData = cycleDoc.data();
      const updatedCycleData = {
        updatedBy,
        patient: {
          ...currentCycleData.patient,
          ...updatedPatientData,
        },
      };
      batch.update(cycleDoc.ref, updatedCycleData);
    });
  }

  const prescriptionsQuery = query(
    collectionGroup(db, "prescriptions"),
    where("status", "==", "active"),
    where("patient.id", "==", updatedPatientData.id)
  );

  const prescriptionsSnapshot = await getDocs(prescriptionsQuery);

  for (let prescriptionDoc of prescriptionsSnapshot.docs) {
    const currentPrescriptionData = prescriptionDoc.data();
    // If we are updating the user's defaultServiceLocation, we will need to check if the user has an appointment scheduled (check for the existence of a non-null appointmentDateTime). If they do, we should prompt the user if they would like to overwrite existing appointments with this updated defaultServiceLocation or not.
    const currentAppointmentDateTime =
      currentPrescriptionData.appointmentDateTime;

    const defaultServiceLocationAddressChanged =
      updatedPatientData.defaultServiceLocation.address !==
      currentDefaultServiceLocation?.address;

    const promptUserAboutServiceLocationChange =
      currentAppointmentDateTime && defaultServiceLocationAddressChanged;

    let shouldChangeCurrentAppointmentLocation = null;
    if (promptUserAboutServiceLocationChange) {
      await confirm({
        title: "Change scheduled appointment?",
        description: `There is an appointment scheduled on ${format(
          ensureValueIsDate(currentAppointmentDateTime),
          "M/d/yyyy"
        )} at ${format(
          ensureValueIsDate(currentAppointmentDateTime),
          "p"
        )} for ${currentPrescriptionData.medication.name} ${
          currentPrescriptionData.medication.strength
        } with a service location of ${
          currentPrescriptionData.appointmentServiceLocation.address
        }. Do you want to update the service location of that appointment?`,
      })
        .then(() => (shouldChangeCurrentAppointmentLocation = true))
        .catch(() => {
          shouldChangeCurrentAppointmentLocation = false;
        });
    }

    const updatedPrescriptionData = {
      updatedBy,
      ...(shouldChangeCurrentAppointmentLocation
        ? {
            appointmentServiceLocation:
              updatedPatientData.defaultServiceLocation,
          }
        : null),
      patient: {
        ...currentPrescriptionData.patient,
        ...updatedPatientData,
      },
    };
    batch.update(prescriptionDoc.ref, updatedPrescriptionData);
    if (
      currentPrescriptionData.calendarEventId &&
      currentPrescriptionData.nextInjectionDate
    ) {
      updateGoogleCalendarEvent(currentPrescriptionData.calendarEventId, {
        ...currentPrescriptionData,
        ...updatedPrescriptionData,
      });
    }
  }

  return batch.commit();
}

export function usePatientSubscription({
  patientId,
  handleUpdate,
}: {
  patientId: string;
  handleUpdate: Function;
}) {
  useEffect(() => {
    if (!patientId) {
      return;
    }
    let didCancel = false;

    const patientRef = doc(db, "patients", patientId);

    const unsubscribe = onSnapshot(patientRef, (patientDoc) => {
      const patient = patientDoc.data() as Patient;
      if (!didCancel && patient) {
        handleUpdate({
          ...patient,
          id: patientDoc.id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          birthdate: (patient.birthdate as Timestamp).toDate(),
        });
      }
    });

    return () => {
      unsubscribe();
      didCancel = true;
    };
  }, [handleUpdate, patientId]);
}

export function useAllPatientsSubscription(
  handleUpdate: any,
  showOnlyActive = false
) {
  useEffect(() => {
    let didCancel = false;

    const patientCollectionRef = collection(db, "patients");
    const patientQuery = query(
      patientCollectionRef,
      where(
        "status",
        "in",
        showOnlyActive ? ["active"] : ["active", "inactive"]
      ),
      orderBy("lastName")
    );

    const unsubscribe = onSnapshot(patientQuery, (patientsSnapshot) => {
      const patients: any[] = [];
      patientsSnapshot.forEach((patientDoc) => {
        const patientId = patientDoc.id;
        const patient = patientDoc.data();
        patients.push({
          ...patient,
          id: patientId,
          prescriptions: [],
          // define new properties here
          birthdate: patient.birthdate.toDate(),
        });
      });
      if (!didCancel) {
        handleUpdate(patients);
      }
    });

    return () => {
      didCancel = true;
      unsubscribe();
    };
  }, [handleUpdate, showOnlyActive]);
}

export function createProfileLink(patientId: any) {
  return `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://injections.rexrx.com"
  }/patients/${patientId}`;
}

export type Patient = {
  id: string;
  firstName: string;
  lastName: string;
  birthdate: Date | Timestamp | string;
  alias: string;
  gender: string;
  allergies?: string;
  phone: string;
  altPhone: string;
  homeAddress: string;
  defaultServiceLocation: {
    description: string;
    address: string;
  };
  locationComments: string;
  patientComments: string;
  defaultDispensingPharmacy: string;
};
